import React from "react";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// context
import { Button, Grid } from "@material-ui/core";
import TablesMedico from "../tables/TablesMedico";
import TablesEstudainte from "../tables/TablesEstudiante";
import TablesAfines from "../tables/TablesAfines";

function HomeAdmin() {
  // global
  var classes = useStyles();
  // const { userState } = useUser();

  const [modo, setModo] = React.useState(0);

  const cambiarModo = (modo) => setModo(modo);

  return (
    <>
      <Grid container direction={"row"}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: "2vh" }}>
          <Grid container direction={"row"} style={{ marginTop: 20 }}>
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => cambiarModo(1)}>
                Ver registro usuarios médico
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => cambiarModo(2)}>
                Ver registro usuarios estudiantes
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => cambiarModo(3)}>
                Ver registro usuarios personas afines
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: "2vh" }}>
          {modo === 1 ? <TablesMedico /> : <></>}
          {modo === 2 ? <TablesEstudainte /> : <></>}
          {modo === 3 ? <TablesAfines /> : <></>}
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(HomeAdmin);
