import React, { useState, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Formulario from "./Formulario";
import { analitics } from "../../services/firebase";
import useStyles from "./styles";
import { Box } from "@material-ui/core";

function Layout() {
  var classes = useStyles();

  const [estado, setEstado] = React.useState(false);
  const [acepta, setAcepta] = React.useState(false);
  const [datos, setDatos] = React.useState({
    nombre: "",
    correo: "",
    telefono: "",
    colegiado: "",
    profesion: "",
    acompanante: false,
    recoger: "",
    nombreSede: "",
  });

  const cambiarEstato = () => {
    setEstado(true);
  };

  const cambiarAcepta = () => {
    setAcepta(!acepta);
  };

  useEffect(() => {
    // analitics.logEvent('Nueva visita',{name:"pagina principal"});
  }, []);

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <div className={classes.fondo}>
          <Container maxWidth="sm">
            <Formulario
              cambiar={cambiarEstato}
              inicio={cambiarAcepta}
              datos={datos}
              setDatos={setDatos}
            />
          </Container>
        </div>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <div>
          <Container maxWidth="sm">
            <Formulario
              cambiar={cambiarEstato}
              inicio={cambiarAcepta}
              datos={datos}
              setDatos={setDatos}
            />
          </Container>
        </div>
      </Box>
    </>
  );
}

export default Layout;
