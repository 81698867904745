import { makeStyles } from "@material-ui/styles";

import imagenFondo from "../../images/cm/fondoForm2.png";
export default makeStyles((theme) => ({
  fondo: {
    backgroundImage: `url(${imagenFondo})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // filter: 'opacity(0.5) drop-shadow(0 0 0 blue)',
    // position: 'absolute',
    // width: '100vh',
    // height: '100vh',
  },
  centered: {
    height: "80vh",
  },
  centered2: {
    height: "30vh",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  imagen2: {
    width: "100%",
    margin: "auto",
  },

  estiloboton: {
    width: "100%",
  },
  estiloboton2: {
    width: "70%",
  },

  textotitulo: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  textoDescripcion: {
    fontWeight: "bolder !important",
  },

  imagenConfirmacionTitulo: {
    width: "50%",
    display: "block",
    margin: "auto",
  },

  profesionLabel: {
    fontSize: "1.3rem",
    color: "#c38b5d",
  },

  colorTextoGala: {
    color: "#000000",
  },

  mostrarTxtPc: {
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mostratTxtMovil: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  textoNegrita: {
    fontWeight: "bolder !important",
    display: "inline",
  },

  imgRecomendaciones: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },

  formControl: {
    margin: theme.spacing(1),
  },
}));
