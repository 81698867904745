import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: "#fff",
    border: "2px solid #ccc",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    padding: 16,
    display: "flex",
    flexDirection: "column",
  },
  botonIzq: {
    marginBottom: "10px",
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
}));
