import React, { useState, useEffect } from "react";
//Material ui
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import Widget from "../../components/Widget/Widget";
//Project imports
import { db, funciones_fb, analitics } from "../../services/firebase";
import config from "../../config";

//img
import logoComision from "../../images/cm/LogoComision.png";
import logoColegio from "../../images/cm/LogoColegio.png";

function FormularioAceptacion(props) {
  const { cambiar, datos, setDatos } = props;

  var classes = useStyles();

  useEffect(() => {
    // loadReCaptcha("6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI");
  }, []);

  const goHome = () => {
    window.location.href = "https://congresonacionaldemedicina.org.gt/";
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />

      <Grid
        item
        xs={12}
        style={{
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        <Grid
          container
          spacing={4}
          alignItems={"center"}
          justify={"space-between"}
          direction={"row"}
          
        >
          <Grid container direction={"row"}>
            <Grid item xs={4} sx={{ pb: 10 }}></Grid>
            <Grid item xs={4} sx={{ pb: 10 }} style={{ display: "flex" }}>
              <img
                className={classes.img}
                alt="complex"
                src={logoComision}
                style={{
                  marginTop: "5vh",
                  maxWidth: "40%",
                  marginLeft: "5vh",
                }}
              />
              <img
                className={classes.img}
                alt="complex"
                src={logoColegio}
                style={{ marginTop: "5vh", maxWidth: "40%" }}
              />
            </Grid>
            <Grid item xs={4} sx={{ pb: 10 }}></Grid>
          </Grid>
          <Grid container direction={"row"}>
            <Grid item xs={12} style={{ margin: "2vh" }}>
              <Typography
                align={"center"}
                variant={"h5"}
                className={classes.textotitulo}
                color={"primary"}
                style={{ color: "#585756", marginTop: "1vh" }}
              >
                LXV CONGRESO NACIONAL DE MEDICINA
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={"center"}>
                IX CONGRESO DE MÉDICOS ESCRITORES
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ margin: "2vh" }}>
              <Typography
                align={"center"}
                variant={"h4"}
                className={classes.textotitulo}
                color={"primary"}
                style={{
                  color: "#585756",
                  marginTop: "5vh",
                  fontWeight: "bold",
                }}
              >
                Condiciones para inscripción al congreso COLMEDEGUA
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ margin: "2vh" }}>
              <Typography style={{ marginBottom: "3vh" }}>
                Estimados agremiados:
              </Typography>
              <Typography>
                Agradecemos su interés en participar en el LXV Congreso Nacional
                de Medicina y IX Congreso de Médicos Escritores.
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                A continuación, encontrarán información esencial sobre la
                inscripción y la obtención de diplomas de participación.
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                1. Modalidades de Inscripción:
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                Los participantes pueden inscribirse en este portal en modo
                presencial o virtual.
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                2. Asistencia Presencial, Virtual o Mixta:
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                El sistema ha sido diseñado para que los participantes puedan
                registrar su asistencia de forma presencial, virtual o en un
                formato mixto.
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                3. Sostenibilidad Ambiental:
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                En un esfuerzo por contribuir a la protección del medio
                ambiente, hemos creado un congreso con un mínimo uso de papel y
                plástico. No se emitirán gafetes de asistencia ni se
                proporcionarán diplomas impresos. En su lugar, recibirán
                diplomas electrónicos en formato PDF.
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                4. Código QR de Asistencia:
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                Tras completar la inscripción, el sistema enviará
                automáticamente un código QR a su dispositivo móvil. Este código
                deberá ser guardado y presentado para escaneo por nuestros
                lectores de códigos al ingreso y egreso de las instalaciones del
                Hotel Barceló, no será necesario presentarse a los módulos de
                inscripción para los agremiados que se preinscribieron, estos
                funcionaran exclusivamente para los que no lo hicieron en este
                portal.
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                5. Acceso Virtual y Diploma de Asistencia:
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                Todos los agremiados podrán acceder a la transmisión de las
                conferencias en modo virtual a través de este portal, el sistema
                llevara un registro de cada día que ingreso y del tiempo que
                estuvo conectado cada participante. Los asistentes en modo
                presencial, virtual o mixto para obtener y descargar de este
                portal el diploma de asistencia al congreso, será necesario que
                estén en calidad de colegiado activo antes del cierre de este.
                En caso contrario, no podrán obtener su diploma ni acreditar las
                horas crédito, sin excepción.
              </Typography>

              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                6. Contacto y Consultas:
              </Typography>
              <Typography style={{ marginBottom: "3vh" }}>
                Si tienen alguna pregunta adicional, no duden en ponerse en
                contacto con nosotros a través del correo electrónico:
                &nbsp;<b style={{ fontSize: "large"}}>info@congresonacionaldemedicina.org.gt</b>&nbsp;
                Agradecemos su comprensión y colaboración con estas condiciones.
                Esperamos que disfruten del congreso y que esta información sea
                de utilidad.
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction={"row"}>
            <Grid item xs={6} align={"center"}>
              <Button
                variant="outlined"
                onClick={goHome}
                className={classes.estiloboton2}
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid item xs={6} align={"center"}>
              <Button
                variant="contained"
                className={classes.estiloboton2}
                onClick={cambiar}
                color="secondary"
              >
                ACEPTAR
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} />
          <Grid item xs={12} />
        </Grid>
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
}

export default FormularioAceptacion;
