import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import GetAppIcon from "@material-ui/icons/GetApp";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";

// context
import { Button, Grid, Typography } from "@material-ui/core";
import { useUserState } from "../../context/UserContext";
import { useUser } from "../../context/UserContext2";
import Home from "../../pages/home/Home";
import HomeAdmin from "../../pages/admin/Home";
import HeaderAdmin from "../Header/HeaderAdmin";

function Layout(props) {
  // global
  var classes = useStyles();
  // const { userState } = useUser();
  // console.log(userState);
  var { isAuthenticated } = useUserState();

  return (
    <>
      {isAuthenticated ? (
        <>
          <div className={classes.root}>
            <HeaderAdmin history={props.history} />
          </div>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <HomeAdmin />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </>
      ) : (
        <>
          <div className={classes.root}>
            <Header history={props.history} />
          </div>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Home />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </>
      )}
    </>
  );
}

export default withRouter(Layout);
