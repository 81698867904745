import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// pages
import LayoutFormulario from "../pages/formulario/Layout";
import LayoutLogin from "../pages/logi/Layout";
// import ExpaleRecaptcha from '../pages/formulario/ExpaleRecaptcha';
import Login from "../pages/loginFirebase";
// import HomeCertificado from "../pages/certificado/Home";
// components
import Layout from "./Layout";
import Asistencia from "../pages/asistencia/Asistencia";

// context
import { useUserState } from "../context/UserContext";
// import { useUser } from "../context/UserContext2";

// import { RouterRounded } from "@material-ui/icons";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  // console.log("admin", useUserState());
  // var { isAuthenticated } = false;
  // const { userState } = useUser();

  const userStateString = localStorage.getItem("login");
  const userState = JSON.parse(userStateString);
  // console.log("user", userState);
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/formulario" render={() => <Redirect to="/" />} />

        {isAuthenticated ? (
          <PrivateRoute path="/admin/app" component={Layout} />
        ) : (
          <PrivateRoute path="/app" component={Layout} />
        )}

        <PrivateRoute path="/asistencia" component={Asistencia} />

        {/* <PublicRoute path="/cer/:id" component={HomeCertificado} /> */}
        <PublicRoute path="/admin" component={Login} />
        <PublicRoute path="/login" component={LayoutLogin} />
        <PublicRoute path="/" component={LayoutFormulario} />
        {/* <Route path="/" component={LayoutFormulario} /> */}

        <Redirect to="/" />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          userState?.isAuthenticated ? (
            React.createElement(component, props)
          ) : isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          userState?.isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/app",
              }}
            />
          ) : isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/admin/app",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
