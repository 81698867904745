import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Box,
  Modal,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Menu as MenuIcon } from "@material-ui/icons";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers/Wrappers";

//img
import logoComision from "../../images/cm/LogoComision.png";
import logoColegio from "../../images/cm/LogoColegio.png";
import { useUser } from "../../context/UserContext2";
import config from "../../config";

export default function Header(props) {
  var classes = useStyles();
  // var { user } = useUserState();

  // global
  // const { userState, setUserState } = useUser();
  const userStateString = localStorage.getItem("login");
  let userState = JSON.parse(userStateString);

  //asistencia
  const [asitenciaMartes, setAsitenciaMartes] = React.useState(false);
  const [asitenciaMiercoles, setAsitenciaMiercoles] = React.useState(false);
  const [asitenciaJueves, setAsitenciaJueves] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const abrirModal = () => setOpen(true);
  const cerrarModal = () => setOpen(false);

  //Para probar que si asistio todos los dias
  const pruebaFecha = [
    { fecha_hora: "2023-11-28 19:06:14" },
    { fecha_hora: "2023-11-28 19:06:14" },
    { fecha_hora: "2023-11-29 19:06:14" },
    { fecha_hora: "2023-11-30 19:06:14" },
  ];

  const fechaActual = new Date();

  //Para verificar si cumple los requisitos para generar el certificado
  // const fechaEspecifica = new Date("2023-11-21T00:00:00-06:00");

  const fechaEspecifica = new Date("2023-12-01T00:00:00-06:00");
  const fechaPosterior = fechaActual >= fechaEspecifica;
  // local
  var [profileMenu, setProfileMenu] = useState(null);

  useEffect(() => {
    const asistencia = Object.values(userState.dataUser?.asistencia ?? {});
    if (asistencia.length !== 0) {
      for (let index = 0; index < asistencia.length; index++) {
        verificar(asistencia[index].fecha_hora);
      }

      // Probar que si asistio todo los dias
      // for (let index = 0; index < pruebaFecha.length; index++) {
      //   verificar(pruebaFecha[index].fecha_hora);
      // }
    }
  }, []);

  const cerrar = () => {
    let dataLogin = {
      isAuthenticated: false,
      dataUser: null,
      tipo: 0,
    };
    const dataJSONString = JSON.stringify(dataLogin);
    localStorage.setItem("login", dataJSONString);
    window.location.reload();
    // setUserState({
    //   isAuthenticated: false,
    //   user: null,
    //   tipo: 0,
    // });
  };

  const descargarCertificado = () => {
    const userStateString2 = localStorage.getItem("login");
    userState = JSON.parse(userStateString2);
    // console.log(userState);

    let stringData = {};

    if (userState?.tipo === 1) {
      if (
        userState?.dataUser?.profesion === "Médico" ||
        userState?.dataUser?.profesion === undefined
      ) {
        stringData = {
          idFirebase: userState.dataUser.key,
          nombre: userState.dataUser.nombre,
          numero: userState.dataUser.colegiado,
          profesion: "DR",
        };
      } else {
        stringData = {
          idFirebase: userState.dataUser.key,
          nombre: userState.dataUser.nombre,
          numero: userState.dataUser.colegiado,
          profesion: "LIC",
        };
      }
    } else if (userState?.tipo === 2) {
      stringData = {
        idFirebase: userState.dataUser.key,
        nombre: userState.dataUser.nombre,
        numero: userState.dataUser.carnet,
        profesion: "EST",
      };
    } else {
      return;
    }

    fetch(`${config.server}/getToken`, {
      method: "POST",
      body: JSON.stringify({
        username: "cmedicina",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(`${config.server}/diploma`, {
          method: "POST",
          body: JSON.stringify(stringData),
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Diploma_CongresoMedico_${stringData.numero}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      });
  };

  const verificar = (fecha) => {
    const partesFechaHora = fecha.split(" ");
    const partesFecha = partesFechaHora[0].split("-");

    const year = parseInt(partesFecha[0]);
    const month = parseInt(partesFecha[1]);
    const day = parseInt(partesFecha[2]);

    if (year === 2023 && month === 11 && day === 28) {
      setAsitenciaMartes(true);
    }
    if (year === 2023 && month === 11 && day === 29) {
      setAsitenciaMiercoles(true);
    }
    if (year === 2023 && month === 11 && day === 30) {
      setAsitenciaJueves(true);
    }
  };

  return (
    <>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img
            alt="complex"
            src={logoComision}
            style={{
              width: "6vh",
              marginRight: "3vh",
            }}
          />
          <img alt="complex" src={logoColegio} style={{ width: "6vh" }} />
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            LXV Congreso Nacional de Medicina
          </Typography>
          <div className={classes.grow} />

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            {userState?.tipo === 1 || userState.tipo === 2 ? (
              <Button
                variant="contained"
                style={{ margin: "2vh", borderRadius: "16px" }}
                onClick={descargarCertificado}
              >
                <GetAppIcon />
                Certificado
              </Button>
            ) : (
              <></>
            )}

            <Button
              variant="contained"
              style={{ margin: "2vh", borderRadius: "16px" }}
              onClick={cerrar}
            >
              Cerrar Sesión
            </Button>
          </Box>

          <Box
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <IconButton
              aria-haspopup="true"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <MenuIcon classes={{ root: classes.headerIcon }} />
            </IconButton>
          </Box>

          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              {/* <Button
                variant="contained"
                style={{ margin: "2vh", borderRadius: "16px" }}
                onClick={abrirModal}
              >
                <GetAppIcon />
                Certificado
              </Button> */}
              <Button
                variant="contained"
                style={{ margin: "2vh", borderRadius: "16px" }}
                onClick={cerrar}
              >
                Cerrar Sesión
              </Button>
            </div>
          </Menu>
        </Toolbar>
      </AppBar>
      <Modal open={open} onClose={cerrarModal}>
        <Box className={classes.modal}>
          {!fechaPosterior ? (
            <>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", marginBottom: "5px" }}
              >
                Requisito para generar el certificado
              </Typography>
              <Typography style={{ marginTop: 4, marginBottom: 4 }}>
                - El certificado estará disponible para su generación una vez
                concluido el congreso.
              </Typography>
              <Typography style={{ marginTop: 4, marginBottom: 4 }}>
                - Haber realizado el pago de forma satisfactoria.
              </Typography>
            </>
          ) : (
            <>
              <>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "bold", marginBottom: "5px" }}
                >
                  Gracias por participar del LXV CONGRESO NACIONAL DE MEDICINA,
                  su asistencia ha sido registrada por el sistema, pronto podrá
                  descargar tu diploma de participación
                </Typography>
                {/* <Typography style={{ marginTop: 4, marginBottom: 4 }}>
                  - {asitenciaMartes ? <>Si </> : <>No </>} asistió el día
                  martes 28 de noviembre del 2023.
                </Typography>
                <Typography style={{ marginTop: 4, marginBottom: 4 }}>
                  - {asitenciaMiercoles ? <>Si </> : <>No </>} asistió el día
                  miercoles 29 de noviembre del 2023.
                </Typography>
                <Typography style={{ marginTop: 4, marginBottom: 4 }}>
                  - {asitenciaJueves ? <>Si </> : <>No </>} asistió el día
                  jueves 30 de noviembre del 2023.
                </Typography> */}
                {/* <Typography style={{ marginTop: 8, marginBottom: 4 }}>
                  - {userState.dataUser?.pago ? <>Si </> : <>No </>} realizo el
                  pago.
                </Typography> */}
                {/* {userState.dataUser?.pago ? (
                  <>
                    {asitenciaMartes ? (
                      <>
                        {asitenciaMiercoles ? (
                          <>
                            {asitenciaJueves ? (
                              <>
                                <Button
                                  variant="contained"
                                  color="success"
                                  className={classes.botonIzq}
                                >
                                  <GetAppIcon />
                                  Descargar certificado
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )  */}
                {userState.dataUser?.pago ? (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      className={classes.botonIzq}
                      disabled
                    >
                      <GetAppIcon />
                      Descargar certificado
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </>
            </>
          )}

          <Button
            variant="contained"
            color="secondary"
            className={classes.botonIzq}
            onClick={cerrarModal}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
}
