import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// context
import { Button, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import TablesAsistencia from "../tables/TablesAsistencia";
import { Typography } from "../../components/Wrappers/Wrappers";
import config from "../../config";

function Asistencia() {
  // global
  var classes = useStyles();
  // const { userState } = useUser();
  const userStateString = localStorage.getItem("login");
  const userState = JSON.parse(userStateString);

  const [asistenciaMartes, setAsitenciaMartes] = React.useState([]);
  const [asistenciaMiercoles, setAsitenciaMiercoles] = React.useState([]);
  const [asistenciaJueves, setAsitenciaJueves] = React.useState([]);
  // console.log(userState);

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.server}/login/${userState.tipo}`, {
        method: "POST",
        body: JSON.stringify({
          correo: userState.dataUser?.correo,
          telefono: userState.dataUser?.telefono,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const datas = await response.json();

      const asistencia = Object.values(datas?.asistencia ?? {});
      if (asistencia.length !== 0) {
        for (let index = 0; index < asistencia.length; index++) {
          verificar(asistencia[index]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // console.log(userState);
    // const asistencia = Object.values(userState.dataUser?.asistencia ?? {});
    // if (asistencia.length !== 0) {
    //   for (let index = 0; index < asistencia.length; index++) {
    //     verificar(asistencia[index]);
    //   }
    // }
  }, []);

  const verificar = (dataAsistencia) => {
    const partesFechaHora = dataAsistencia?.fecha_hora.split(" ");
    const partesFecha = partesFechaHora[0].split("-");
    const partesHora = partesFechaHora[1].split("-");

    const year = parseInt(partesFecha[0]);
    const month = parseInt(partesFecha[1]);
    const day = parseInt(partesFecha[2]);

    let jsonData = {};

    if (dataAsistencia?.sala === 1) {
      jsonData = [
        {
          sala: "Salón Naciones 1",
          hora: partesHora[0],
        },
      ];
    } else if (dataAsistencia?.sala === 2) {
      jsonData = [
        {
          sala: "Salón Naciones 2",
          hora: partesHora[0],
        },
      ];
    } else if (dataAsistencia?.sala === 3) {
      jsonData = [
        {
          sala: "Salón Dorado",
          hora: partesHora[0],
        },
      ];
    } else if (dataAsistencia?.sala === 4) {
      jsonData = [
        {
          sala: "Salón Reyes",
          hora: partesHora,
        },
      ];
    }

    // console.log(jsonData);
    // console.log(year);
    // console.log(month);
    // console.log(month);

    try {
      if (year === 2023 && month === 11 && day === 28) {
        setAsitenciaMartes((prevData) => [...prevData, ...jsonData]);
      }
      if (year === 2023 && month === 11 && day === 29) {
        setAsitenciaMiercoles((prevData) => [...prevData, ...jsonData]);
      }
      if (year === 2023 && month === 11 && day === 30) {
        setAsitenciaJueves((prevData) => [...prevData, ...jsonData]);
      }
    } catch (error) {}
  };

  // console.log(asistenciaMartes)
  return (
    <Grid container direction={"row"}>
      <Grid
        item
        xs={12}
        align={"center"}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Typography variant="h1">Tabla de asistencias</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 15 }}>
        <Typography variant="h2">28/11/2023</Typography>
        <TablesAsistencia datosAsistencia={asistenciaMartes} />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 15 }}>
        <Typography variant="h2">29/11/2023</Typography>

        <TablesAsistencia datosAsistencia={asistenciaMiercoles} />
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: 15 }}>
        <Typography variant="h2">30/11/2023</Typography>

        <TablesAsistencia datosAsistencia={asistenciaJueves} />
      </Grid>
    </Grid>
  );
}

export default withRouter(Asistencia);
