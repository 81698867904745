import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db } from "../../services/firebase";
// components
import CircularProgress from "@material-ui/core/CircularProgress";

// styles
import useStyles from "./styles";

export default function TablesAsistencia({ datosAsistencia }) {
  var classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [visible, setVisible] = React.useState(true);
  const [columnas, setColumnas] = React.useState([
    {
      name: "sala",
      label: "Sala",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "hora",
      label: "Hora",
      options: {
        filter: true,
        sort: true,
      },
    },
  ]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {visible && (
            <MUIDataTable
              options={{
                selectableRows: "none",
                rowsPerPage: 5,
              }}
              data={datosAsistencia}
              columns={columnas}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
