import React, { useState, useEffect } from "react";
//Material ui
import { Grid, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
//Project imports
import { db, funciones_fb, analitics, auth } from "../../services/firebase";
import config from "../../config";

//img
import logoComision from "../../images/cm/LogoComision.png";
import logoColegio from "../../images/cm/LogoColegio.png";
import { useUser } from "../../context/UserContext2";

function Formulario(props) {
  const { datos, setDatos } = props;

  // const { userState, setUserState } = useUser();
  var classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = React.useState(false);
  const [emainError, setEmainError] = React.useState("");

  const [correoReg, setCorreoReg] = useState("");
  const [categoria, setCategoria] = useState(0);
  const [consultado, setConsultado] = useState(true);
  const [msgError, setMsgError] = useState("Por favor, intente de nuevo.");

  useEffect(() => {
    // loadReCaptcha("6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI");
  }, []);

  const handleChangeCorreo = (event) => {
    // const regexe = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;

    setDatos({
      ...datos,
      correo: event.target.value,
    });
    // if (!regexe.test(datos.correo)) {
    //   setEmainError("Error ingreso un correo no valido");
    // } else {
    //   setEmainError("");
    // }
  };

  const handleChangeTelefono = (event) => {
    const numTelefono = event.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(numTelefono) || numTelefono === "") {
      setDatos((prevDatos) => ({
        ...prevDatos,
        telefono: numTelefono,
      }));
    }
  };

  const fetchGuardar = (categoria, json) => {
    return fetch(`${config.server}/login/${categoria}`, {
      method: "POST",
      body: json,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response);
  };

  const ConsultarDatos = async () => {
    setError(false);
    try {
      setIsLoading(true);
      let dataJson = JSON.stringify(datos);
      await fetchGuardar(categoria, dataJson)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error("Error login");
        })
        .then((datas) => {
          auth.signOut()
          let dataLogin = {
            isAuthenticated: true,
            dataUser: datas,
            tipo: categoria,
          };
          const dataJSONString = JSON.stringify(dataLogin);
          localStorage.setItem("login", dataJSONString);
          // setUserState({ isAuthenticated: true, dataUser: datas, tipo: categoria});
          setIsLoading(false);
          window.location.reload();
        });
    } catch (error) {
      setIsLoading(false);
      setError(true);
      setCorreoReg("error");
      setMsgError("Error de credenciales.");
    }
  };

  const escogerModo = () => {
    setConsultado(true);
    setDatos({
      correo: "",
      telefono: "",
    });
  };

  const modoMedico = () => {
    setConsultado(false);
    setCategoria(1);
    setDatos({
      correo: "",
      telefono: "",
    });
  };

  const modoEstudiante = () => {
    setConsultado(false);
    setCategoria(2);
    setDatos({
      correo: "",
      telefono: "",
    });
  };

  const modoPublico = () => {
    setConsultado(false);
    setCategoria(3);
    setDatos({
      correo: "",
      telefono: "",
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />

      <Grid
        item
        xs={12}
        style={{
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255)",
        }}
      >
        {
          !consultado ? (
            <Grid
              container
              spacing={4}
              alignItems={"center"}
              justify={"space-between"}
              direction={"row"}
            >
              <Grid container direction={"row"}>
                <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                <Grid item xs={3} sx={{ pb: 10 }}>
                  <img
                    className={classes.img}
                    alt="complex"
                    src={logoComision}
                    style={{
                      marginTop: "5vh",
                      maxWidth: "90%",
                    }}
                  />
                </Grid>
                <Grid item xs={3} sx={{ pb: 10 }}>
                  <img
                    className={classes.img}
                    alt="complex"
                    src={logoColegio}
                    style={{ marginTop: "5vh", maxWidth: "90%" }}
                  />
                </Grid>
                <Grid item xs={3} sx={{ pb: 10 }}></Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  align={"center"}
                  variant={"h2"}
                  className={classes.textotitulo}
                  color={"primary"}
                >
                  Iniciar Sesión
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  // error={emainError ? true : false}
                  fullWidth
                  value={datos.correo}
                  type="text"
                  onChange={handleChangeCorreo}
                  id="correoElectronico"
                  label="Correo electrónico"
                  variant="outlined"
                  // helperText={emainError ? emainError : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={datos.telefono}
                  onChange={handleChangeTelefono}
                  id="telefono"
                  label="Número de teléfono"
                  variant="outlined"
                />
              </Grid>

              {error && (
                <Grid item xs={12}>
                  <Alert severity={correoReg} onClose={() => setError(false)}>
                    {msgError}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                {isLoading ? (
                  <div className={classes.textotitulo}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    disabled={
                      datos.telefono.length === 0 || datos.correo.length === 0
                      // emainError
                    }
                    variant="contained"
                    color="secondary"
                    onClick={ConsultarDatos}
                    className={classes.estiloboton}
                  >
                    INICIAR SESIÓN
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="center"
                  onClick={escogerModo} // Actualiza el estado de registrarse a true al hacer clic
                  style={{ cursor: "pointer" }}
                >
                  <strong>ATRÁS</strong>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                spacing={6}
                alignItems={"center"}
                justify={"space-between"}
                direction={"row"}
                className={classes.centered}
              >
                <Grid
                  container
                  spacing={4}
                  alignItems={"center"}
                  justify={"space-between"}
                  direction={"row"}
                  className={classes.centered}
                >
                  <Grid container direction={"row"}>
                    <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                    <Grid item xs={3} sx={{ pb: 10 }}>
                      <img
                        className={classes.img}
                        alt="complex"
                        src={logoComision}
                        style={{
                          marginTop: "5vh",
                          maxWidth: "90%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: 10 }}>
                      <img
                        className={classes.img}
                        alt="complex"
                        src={logoColegio}
                        style={{ marginTop: "5vh", maxWidth: "90%" }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                  </Grid>
                  <Grid container direction={"row"}>
                    <Grid item xs={12} style={{ margin: "2vh" }}>
                      <Typography
                        align={"center"}
                        variant={"h5"}
                        className={classes.textotitulo}
                        color={"primary"}
                        style={{ color: "#585756", marginTop: "1vh" }}
                      >
                        LXV CONGRESO NACIONAL DE MEDICINA
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align={"center"}>
                        IX CONGRESO DE MÉDICOS ESCRITORES
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} align={"center"}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={modoMedico}
                      className={classes.estiloboton2}
                    >
                      AGREMIADO
                    </Button>
                  </Grid>

                  <Grid item xs={12} align={"center"}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={modoEstudiante}
                      className={classes.estiloboton2}
                    >
                      ESTUDIANTE
                    </Button>
                  </Grid>
                  <Grid item xs={12} align={"center"}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={modoPublico}
                      className={classes.estiloboton2}
                    >
                      PÚBLICO AFIN
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12} align={"center"}>
                    <Button onClick={irInicio} className={classes.estiloboton2}>
                      Volver al inicio
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} />
                  <Grid item xs={12} />
                </Grid>
              </Grid>
            </>
          )
          // )
        }
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />
    </Grid>
  );
}

export default Formulario;
