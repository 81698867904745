import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { db } from "../../services/firebase";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";

// styles
import useStyles from "./styles";

export default function TablesEstudainte() {
  var classes = useStyles();

  const [Id, setId] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const cerrarModal = () => setOpen(false);
  const [datos, setDatos] = React.useState({});

  const [loading, setLoading] = React.useState(true);
  const [visible, setVisible] = React.useState(true);
  const [columnas, setColumnas] = React.useState([
    {
      name: "key",
      options: {
        display: "excluded", // Oculta la columna 'id' en la tabla
      },
    },
    {
      name: "carnet",
      label: "Carnet",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "telefono",
      label: "Teléfono",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "correo",
      label: "Correo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pago",
      label: "pago",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span style={{ color: value ? "green" : "red" }}>
            {value ? "Pagado" : "No pagado"}
          </span>
        ),
      },
    },
    {
      name: "editar",
      label: "editar",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleEdit(tableMeta.rowData)}
            >
              Editar
            </Button>
          );
        },
      },
    },
    {
      name: "certificado",
      label: "Certificado",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="contained"
              style={{ background: "green", color: "white" }}
              onClick={() => handleDownload(tableMeta.rowData)}
            >
              Descargar
            </Button>
          );
        },
      },
    },
  ]);

  const handleEdit = (rowData) => {
    setOpen(true);
    setId(rowData[0]);
    setDatos({
      carnet: rowData[2],
      nombre: rowData[3],
      telefono: rowData[4],
      correo: rowData[5],
      pago: rowData[6],
      eliminado: false,
    });
    // console.log("Editar clickeado para la fila:", rowData);
  };

  const handleDownload = (rowData) => {
    // console.log("Download: ", rowData);
    let stringData = {
      idFirebase: rowData[0],
      nombre: rowData[2],
      numero: rowData[1],
      profesion: "EST",
    };

    fetch(`${config.server}/getToken`, {
      method: "POST",
      body: JSON.stringify({
        username: "cmedicina",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(`${config.server}/diploma`, {
          method: "POST",
          body: JSON.stringify(stringData),
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Diploma_CongresoMedico_${stringData.numero}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      });
  };

  const [datosClientes, setDatosClientes] = React.useState([]);

  const fetchObtenerEstudiante = (categoria, token) => {
    return fetch(`${config.server}/get_all_data/${categoria}`, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }).then((response) => response);
  };

  const fetchEstudianteData = async (token) => {
    try {
      const responseGet = await fetchObtenerEstudiante(2, token);
      const dataGet = await responseGet.json();

      if (dataGet.length !== 0) {
        setDatosClientes((prevData) => [...prevData, ...dataGet]);
        await fetchEstudianteData(token);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.server}/getToken`, {
        method: "POST",
        body: JSON.stringify({
          username: "cmedicina",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      await fetchEstudianteData(data.token);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    setVisible(false);
    setVisible(true);
    setLoading(false);
  }, []);

  const handleChangeCarnet = (event) => {
    setDatos({
      ...datos,
      carnet: event.target.value,
    });
  };
  const handleChangeNombre = (event) => {
    setDatos({
      ...datos,
      nombre: event.target.value,
    });
  };
  const handleChangeTelefono = (event) => {
    setDatos({
      ...datos,
      telefono: event.target.value,
    });
  };
  const handleChangeCorreo = (event) => {
    setDatos({
      ...datos,
      correo: event.target.value,
    });
  };
  const handleChangePago = (event) => {
    setDatos({
      ...datos,
      pago: event.target.value,
    });
  };

  const editar = () => {
    // console.log(datos);
    fetch(`${config.server}/getToken`, {
      method: "POST",
      body: JSON.stringify({
        username: "cmedicina",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(`${config.server}/update_data/2/${Id}`, {
          method: "PUT",
          body: JSON.stringify(datos),
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        }).then((response) => {
          // console.log(response.status);
          if (response.status === 200) {
            fetchData();
            setDatosClientes([]);
            setOpen(false);
          }
        });
      });
  };

  return (
    <>
      <PageTitle title="Registros de los usuarios estudiantes" />
      {loading && (
        <div>
          loading...
          <CircularProgress />
        </div>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {visible && (
            <MUIDataTable
              options={{
                selectableRows: "none",
              }}
              data={datosClientes}
              columns={columnas}
            />
          )}
        </Grid>
      </Grid>

      <Modal open={open} onClose={cerrarModal}>
        <Box className={classes.modal}>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", marginBottom: "5px" }}
          >
            Editar Usuario
          </Typography>
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            fullWidth
            value={datos?.carnet}
            onChange={handleChangeCarnet}
            id="carnet"
            label="No. de Carnet*"
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            required
            fullWidth
            value={datos?.nombre}
            onChange={handleChangeNombre}
            id="nombreCompleto"
            label="Nombre completo"
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            required
            fullWidth
            value={datos?.telefono}
            onChange={handleChangeTelefono}
            id="telefono"
            label="Número de teléfono"
            variant="outlined"
          />
          <TextField
            style={{ marginTop: 10, marginBottom: 10 }}
            required
            fullWidth
            value={datos?.correo}
            type="text"
            onChange={handleChangeCorreo}
            id="correoElectronico"
            label="Correo electrónico"
            variant="outlined"
          />

          <FormControl
            variant="filled"
            fullWidth
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <InputLabel>Pago</InputLabel>
            <Select
              value={datos?.pago}
              label="pago"
              onChange={handleChangePago}
            >
              <MenuItem value={true}>Pagado</MenuItem>
              <MenuItem value={false}>No Pagado</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            className={classes.botonIzq}
            onClick={editar}
          >
            Guardar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.botonIzq}
            onClick={cerrarModal}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
}
