import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import GetAppIcon from "@material-ui/icons/GetApp";

// styles
import useStyles from "./styles";

// context
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useUser } from "../../context/UserContext2";
import PagVideo from "../video/pagVideo";
import { useEffect } from "react";
import horarios from "./Horario";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import config from "../../config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Alert } from "@material-ui/lab";

function Home() {
  const [datosActuales, setDatosActuales] = React.useState([]);
  const [dia, setDia] = React.useState(0);
  const [datos, setDatos] = React.useState({});

  //Error
  const [profesionError, setProfesionError] = React.useState(false);
  const [colegiadoError, setColegiadoError] = React.useState(false);
  const [carnetError, setCarnetError] = React.useState(false);
  const [nombreError, setNombreError] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [msgError, setMsgError] = React.useState(
    "Por favor, intente de nuevo."
  );

  const [open, setOpen] = React.useState(false);
  const cerrarModal = () => setOpen(false);
  const abrirModal = () => setOpen(true);

  // global
  var classes = useStyles();
  // const { userState } = useUser();
  const userStateString = localStorage.getItem("login");
  let userState = JSON.parse(userStateString);

  // console.log(userState.tipo);

  //State congreso terminado
  // const [end, setEnd] = React.useState(false);

  //State ver video
  const [video, setvideo] = React.useState(false);
  const [dataVideo, setDataVideo] = React.useState({});

  const fechaActual = new Date();

  //Para verificar si ya se terminp el congreso
  // const fechaEspecifica = new Date("2023-11-21T00:00:00-06:00");
  const fechaEspecifica = new Date("2023-12-01T00:00:00-06:00");
  const end = fechaActual >= fechaEspecifica;

  const opcionesFecha = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/London",
  };

  const obtenerHoraEnHusoHorario = (desplazamiento) => {
    const horaEnDesplazamiento = new Date();
    horaEnDesplazamiento.setHours(
      horaEnDesplazamiento.getHours() + desplazamiento
    );
    return horaEnDesplazamiento.toLocaleString("es-ES", opcionesFecha);
  };

  const obtenerDia = () => {
    const horaActual = new Date();
    const diaActual = horaActual.getDate();
    return diaActual;
  };

  useEffect(() => {
    setDatos(userState?.dataUser);
    const obtenerDatosSegunHorario = () => {
      let diaActual = obtenerDia();
      const horaFormateada = obtenerHoraEnHusoHorario(-6);

      /***  28 es martes, 29 miercoles y 30 jueves ***/
      // diaActual = 28
      // diaActual = 29
      // diaActual = 30

      /*** Prueba para ver que se actualice cada minuto ***/
      // diaActual = 31;

      setDia(diaActual);
      let horarioActual;

      if (diaActual === 28) {
        horarioActual = horarios.martes.find(
          (horario) =>
            horaFormateada >= horario.horaInicio &&
            horaFormateada <= horario.horaFin
        );
      } else if (diaActual === 29) {
        horarioActual = horarios.miercoles.find(
          (horario) =>
            horaFormateada >= horario.horaInicio &&
            horaFormateada <= horario.horaFin
        );
      } else if (diaActual === 30) {
        horarioActual = horarios.jueves.find(
          (horario) =>
            horaFormateada >= horario.horaInicio &&
            horaFormateada <= horario.horaFin
        );
      } else if (diaActual === 31 || diaActual === 27) {
        const horaActual = new Date();
        const minutosActuales = horaActual.getMinutes();

        horarioActual = {
          datos: [
            {
              titulo: "PRUEBA EN SITIO ",
              salon: minutosActuales,
              link: "https://www.youtube.com/embed/G5Pnc7zzHME?si=G4Wwrehqjn8A0jJL",
              sala: 1,
            },
            {
              titulo: "PRUEBA INTERNA",
              salon: minutosActuales,
              link: "https://www.youtube.com/embed/SnlR4Wmv4RM?si=lImBQxezkGi5vQ_w",
              sala: 2,
            },
            // {
            //   titulo: "PRUEBA " + minutosActuales,
            //   salon: "Salón Dorado",
            //   sala: 3,
            // },
            // {
            //   titulo: "PRUEBA " + minutosActuales,
            //   salon: "Salón Reyes",
            //   sala: 4,
            // },
          ],
        };
      }
      if (horarioActual) {
        setDatosActuales(horarioActual.datos);
      }
    };

    const intervalo = setInterval(obtenerDatosSegunHorario, 1000);

    return () => clearInterval(intervalo);
  }, []);

  const verVideo = async (nombre, link, sala) => {
    let dataJson = JSON.stringify({
      usuario: userState.dataUser?.key,
      sala: sala,
    });
    await fetch(`${config.server}/asistencia/${userState.tipo}`, {
      method: "POST",
      body: dataJson,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImNtZWRpY2luYSIsImlhdCI6MTcwMTI2NDc3NiwiZXhwIjoxNzAzODU2Nzc2fQ.vuBIACaI3oDvrwA10tyHeW_1FAnZtKvxj2H7fBXV-wY",
      },
    });

    setDataVideo({ nombre, link });
    setvideo(!video);
  };

  const regresarHome = () => {
    setvideo(!video);
  };

  const abrirNuevaVentana = () => {
    window.open("#/asistencia", "_blank");
  };

  const handleChangeCarnet = (event) => {
    setCarnetError("");

    if (event.target.value.includes(" ")) {
      return;
    }

    const numCarne = event.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(numCarne) || numCarne === "") {
      setDatos((prevDatos) => ({
        ...prevDatos,
        carnet: numCarne,
      }));
    }
  };

  const handleChangeColegiado = (event) => {
    setColegiadoError("");
    if (event.target.value.includes(" ")) {
      return;
    }

    const numColegiado = event.target.value;
    // console.log(event.target.value);
    setDatos((prevDatos) => ({
      ...prevDatos,
      colegiado: numColegiado,
    }));
  };

  const handleChangeProfesion = (event) => {
    setProfesionError("");
    setDatos((prevDatos) => ({
      ...prevDatos,
      profesion: event.target.value,
    }));
  };

  const handleChangeNombre = (event) => {
    setNombreError("");
    setDatos({
      ...datos,
      nombre: event.target.value,
    });
  };

  const fecthEditar = (id, stringData, numEditar) => {
    fetch(`${config.server}/getToken`, {
      method: "POST",
      body: JSON.stringify({
        username: "cmedicina",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(`${config.server}/update_data/${userState?.tipo}/${id}`, {
          method: "PUT",
          body: JSON.stringify(stringData),
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        }).then((response) => {
          if (response.status === 200) {
            let actData = {
              ...datos,
              editar: numEditar,
            };
            let jsonAllData = {
              ...userState,
              dataUser: actData,
            };
            // console.log(jsonAllData);
            const dataJSONString = JSON.stringify(jsonAllData);
            localStorage.setItem("login", dataJSONString);
            userState = actData;
            setOpen(false);
          }
        });
      });
  };

  const editar = () => {
    setError(false);
    if (datos?.nombre.length === 0) {
      setNombreError("Campo obligatorio");
      setError(true);
      setMsgError("Es necesario completar todos los campos obligatorios.");
      return;
    }

    if (userState?.tipo === 1) {
      if (datos?.profesion === "Médico" || datos?.profesion === "Licenciado") {
        if (datos?.colegiado.length === 0) {
          setColegiadoError("Campo obligatorio");
          setError(true);
          setMsgError("Es necesario completar todos los campos obligatorios.");
          return;
        }

        let varEditar = userState.dataUser?.editar ?? 0;

        let stringData = {
          correo: datos?.correo,
          colegiado: datos?.colegiado,
          eliminado: false,
          nombre: datos?.nombre,
          telefono: datos?.telefono,
          profesion: datos?.profesion,
          pago: datos?.pago ?? false,
          editar: varEditar + 1,
        };

        fecthEditar(datos?.key, stringData, varEditar + 1);
      } else {
        setProfesionError("Campo obligatorio");
        setError(true);
        setMsgError("Es necesario completar todos los campos obligatorios.");
      }
    } else {
      if (datos?.carnet.length === 0) {
        setCarnetError("Campo obligatorio");
        setError(true);
        setMsgError("Es necesario completar todos los campos obligatorios.");
        return;
      }

      let varEditar = userState.dataUser?.editar ?? 0;

      let stringData = {
        correo: datos?.correo,
        carnet: datos?.carnet,
        eliminado: false,
        nombre: datos?.nombre,
        telefono: datos?.telefono,
        pago: datos?.pago ?? false,
        editar: varEditar + 1,
      };
      fecthEditar(datos?.key, stringData, varEditar + 1);
    }
  };

  const descargarCertificado = () => {
    const userStateString2 = localStorage.getItem("login");
    userState = JSON.parse(userStateString2);

    // console.log(userState.tipo);

    let stringData = {};

    if (userState?.tipo === 1) {
      if (
        userState?.dataUser?.profesion === "Médico" ||
        userState?.dataUser?.profesion === undefined
      ) {
        stringData = {
          idFirebase: userState.dataUser.key,
          nombre: userState.dataUser.nombre,
          numero: userState.dataUser.colegiado,
          profesion: "DR",
        };
      } else {
        stringData = {
          idFirebase: userState.dataUser.key,
          nombre: userState.dataUser.nombre,
          numero: userState.dataUser.colegiado,
          profesion: "LIC",
        };
      }
    } else if (userState?.tipo === 2) {
      stringData = {
        idFirebase: userState.dataUser.key,
        nombre: userState.dataUser.nombre,
        numero: userState.dataUser.carnet,
        profesion: "EST",
      };
    } else {
      return;
    }

    fetch(`${config.server}/getToken`, {
      method: "POST",
      body: JSON.stringify({
        username: "cmedicina",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetch(`${config.server}/diploma`, {
          method: "POST",
          body: JSON.stringify(stringData),
          headers: {
            "Content-Type": "application/json",
            Authorization: data.token,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Diploma_CongresoMedico_${stringData.numero}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          });
      });
  };

  return (
    <>
      {video ? (
        <PagVideo data={dataVideo} regresar={regresarHome} />
      ) : (
        <Grid container direction={"row"}>
          <Grid item xs={12} sm={12} md={3} lg={3} style={{ margin: "2vh" }}>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <Grid
                container
                alignItems={"center"}
                direction={"row"}
                style={{
                  borderRadius: "16px",
                  backgroundColor: "rgba(	128, 128, 128, 0.1)",
                  padding: "10px",
                }}
              >
                <Grid item xs={12} align={"center"}>
                  Gafete electronico
                </Grid>
                <Grid
                  item
                  xs={12}
                  align={"center"}
                  style={{ marginTop: "1vh", marginBottom: "1vh" }}
                >
                  <img
                    alt="QR"
                    src={`https://qr.congresonacionaldemedicina.org.gt/${userState.dataUser?.key}.png`}
                  />
                </Grid>
                <Grid item xs={12} align={"center"}>
                  <Typography>{userState.dataUser?.nombre}</Typography>
                </Grid>
                <Grid item xs={12} align={"center"}>
                  <Typography>
                    {userState.dataUser?.colegiado ??
                      userState.dataUser?.carnet ??
                      userState.dataUser?.cui}
                  </Typography>
                </Grid>

                {userState?.tipo === 1 ? (
                  <>
                    <Grid item xs={12} style={{ marginTop: "2vh" }}>
                      <Typography>
                        <BusinessCenterIcon style={{ marginRight: "10px" }} />
                        Maletín
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {Object.values(userState.dataUser?.maletin ?? [""])[0]}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "2vh" }}>
                      <Typography>
                        <FreeBreakfastIcon style={{ marginRight: "10px" }} />
                        Coffee Break
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {Object.values(userState.dataUser?.coffee ?? [""])[0]}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {/* <Grid item xs={12} align={"center"} style={{ marginTop: 20 }}>
                <Typography>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={abrirNuevaVentana}
                  >
                    Ver Asistencia
                  </Button>
                </Typography>
              </Grid> */}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid
              container
              alignItems={"center"}
              direction={"row"}
              style={{
                borderRadius: "16px",
                backgroundColor: "rgba(	128, 128, 128, 0.1)",
              }}
            >
              {end ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "3px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="h2" align="justify">
                      {/* Gracias por participar del LXV CONGRESO NACIONAL DE
                      MEDICINA, su asistencia ha sido registrada por el sistema,
                      pronto podrá descargar tu diploma de participación */}
                      Haga clic en el botón "Editar Información" para rectificar
                      sus datos. Podrá descargar su diploma tantas veces como
                      sea necesario pero su información sólo puede ser
                      rectificada un máximo de 2 veces.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={12}
                    style={{
                      margin: "3px",
                      padding: "10px",
                    }}
                  >
                    {(userState.dataUser?.editar ?? 0) < 2 ? (
                      <>
                        {userState?.tipo === 1 || userState?.tipo === 2 ? (
                          <Button
                            variant="contained"
                            style={{
                              margin: "2vh",
                              borderRadius: "16px",
                              backgroundColor: "black",
                              color: "white",
                            }}
                            onClick={abrirModal}
                          >
                            Editar información
                          </Button>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  <Grid
                    item
                    align="center"
                    xs={12}
                    style={{
                      margin: "3px",
                      padding: "10px",
                    }}
                  >
                    {userState?.tipo === 1 || userState.tipo === 2 ? (
                      <Button
                        variant="contained"
                        style={{
                          margin: "2vh",
                          borderRadius: "16px",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onClick={descargarCertificado}
                      >
                        <GetAppIcon />
                        Certificado
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  <Grid
                    item
                    style={{
                      margin: "3px",
                      padding: "10px",
                    }}
                    xs={12}
                  >
                    <Typography
                      variant="h6"
                      align="justify"
                      style={{ marginBottom: 10 }}
                    >
                      Si sus datos aún son incorrectos puede enviar un correo
                      electrónico a{" "}
                      <a
                        href={`mailto:info@congresonacionaldemedicina.org.gt`}
                        style={{ wordWrap: "break-word" }}
                      >
                        info@congresonacionaldemedicina.org.gt
                      </a>{" "}
                      o bien contacte al Colegio de Médicos de Guatemala
                    </Typography>
                    {userState.dataUser?.editar === 2 ? (
                      <Typography variant="h6" style={{ color: "red" }}>
                        Ya no puede editar su información.
                      </Typography>
                    ) : (
                      <Typography
                        variant="h6"
                        style={{ color: "red" }}
                        align="justify"
                      >
                        Todavia puede editar su información{" "}
                        {userState.dataUser?.editar === 1 ? "1 vez" : "2 veces"}
                        .
                      </Typography>
                    )}
                  </Grid>

                  <Modal open={open} onClose={cerrarModal}>
                    <Box className={classes.modal}>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bold", marginBottom: "5px" }}
                      >
                        Editar Usuario
                      </Typography>
                      {userState?.tipo === 1 ? (
                        <>
                          <FormControl
                            variant="filled"
                            fullWidth
                            error={profesionError ? true : false}
                            helperText={profesionError ? profesionError : ""}
                          >
                            <InputLabel>Selecciona tu profesión</InputLabel>
                            <Select
                              value={datos.profesion}
                              label="profesión"
                              onChange={handleChangeProfesion}
                            >
                              <MenuItem value={"Médico"}>Médico (a)</MenuItem>
                              <MenuItem value={"Licenciado"}>
                                Licenciado (a)
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <TextField
                            style={{ marginTop: 10, marginBottom: 10 }}
                            fullWidth
                            value={datos?.colegiado}
                            onChange={handleChangeColegiado}
                            id="colegiado"
                            label="No. de Colegiado*"
                            variant="outlined"
                            error={colegiadoError ? true : false}
                            helperText={colegiadoError ? colegiadoError : ""}
                          />
                        </>
                      ) : (
                        <TextField
                          style={{ marginTop: 10, marginBottom: 10 }}
                          fullWidth
                          value={datos?.carnet}
                          onChange={handleChangeCarnet}
                          id="carnet"
                          label="No. de Carnet*"
                          variant="outlined"
                          error={carnetError ? true : false}
                          helperText={carnetError ? carnetError : ""}
                        />
                      )}

                      <TextField
                        style={{ marginTop: 10, marginBottom: 10 }}
                        required
                        fullWidth
                        value={datos.nombre}
                        onChange={handleChangeNombre}
                        id="nombreCompleto"
                        label="Nombre completo"
                        variant="outlined"
                        error={nombreError ? true : false}
                        helperText={nombreError ? nombreError : ""}
                      />

                      {error && (
                        <Grid item xs={12}>
                          <Alert
                            severity="error"
                            onClose={() => setError(false)}
                          >
                            {msgError}
                          </Alert>
                        </Grid>
                      )}

                      <Button
                        variant="contained"
                        className={classes.botonIzq}
                        onClick={editar}
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.botonIzq}
                        onClick={cerrarModal}
                      >
                        Cerrar
                      </Button>
                    </Box>
                  </Modal>
                </>
              ) : (
                <>
                  {datosActuales.length !== 0 ? (
                    <>
                      {datosActuales.map((dato, index) => (
                        <>
                          {dato.salon === "Descanso" ||
                          dato.salon === "Congreso Médico" ? (
                            <Grid container xs={12} sm={12} md={12} lg={12}>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  border: "3px solid",
                                  borderRadius: "16px",
                                  backgroundColor: "white",
                                  margin: "3px",
                                  padding: "10px",
                                }}
                              >
                                <Grid container xs={12}>
                                  <Grid item xs={12}>
                                    <Typography variant="h2">
                                      {dato.titulo}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6">
                                      {dato.salon}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {dato.salon === "Descanso" ? (
                                      <>
                                        <RestaurantIcon />
                                        <FreeBreakfastIcon />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container xs={12} sm={12} md={6} lg={6}>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  border: "3px solid",
                                  borderRadius: "16px",
                                  backgroundColor: "white",
                                  margin: "3px",
                                  padding: "10px",
                                }}
                              >
                                <Grid container xs={12}>
                                  <Grid item xs={12}>
                                    <Typography variant="h2">
                                      {dato.titulo}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6">
                                      {dato.salon}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        verVideo(
                                          dato.titulo,
                                          dato.link,
                                          dato.sala
                                        )
                                      }
                                    >
                                      Entrar
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Grid container xs={12} sm={12} md={12} lg={12}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            border: "3px solid",
                            borderRadius: "16px",
                            backgroundColor: "white",
                            margin: "3px",
                            padding: "10px",
                          }}
                        >
                          <Grid container xs={12}>
                            <Grid item xs={12}>
                              <Typography variant="h2">
                                CONGRESO NACIONAL DE MEDICINA
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                De 28 al 30 de noviembre
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withRouter(Home);
