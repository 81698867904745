import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

// context
import { Box, Button, Grid, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
function pagVideo({ data, regresar }) {
  // global
  return (
    <>
      <Grid container direction={"row"}>
        <Grid item xs={12} style={{ margin: "2vh" }}>
          <Button
            variant="contained"
            style={{ margin: "2vh", borderRadius: "16px" }}
            onClick={regresar}
          >
            <ArrowBackIcon />
            Atrás
          </Button>
        </Grid>
        <Grid container>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          ></Box>
          <Grid item xs={12} style={{ margin: "2vh" }} align={"center"}>
            <iframe
              width="560"
              height="315"
              src={data?.link}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: "2vh" }} align={"center"}>
          <Typography variant="h3">{data?.nombre}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(pagVideo);
