const horario = {
  martes: [
    {
      horaInicio: "08:00",
      horaFin: "08:59",
      datos: [
        {
          titulo: "INAUGURACIÓN",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
      ],
    },
    {
      horaInicio: "09:00",
      horaFin: "09:59",
      datos: [
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE ENDOCRINOLOGÍA PEDIÁTRICA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "MEDICINA INTERNA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/LVXsgIK2DXs?si=EDrjfvoQCqBP7alc",
          sala: 2,
        },
        {
          titulo: "SALUD PÚBLICA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN DE CIRUJANOS DE GUATEMALA",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "10:00",
      horaFin: "10:29",
      datos: [
        {
          titulo: "CAFÉ",
          salon: "Descanso",
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/LVXsgIK2DXs?si=EDrjfvoQCqBP7alc",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "CONFERENCIA SALÓN REYES",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "10:30",
      horaFin: "12:29",
      datos: [
        {
          titulo: "ASOCIACIÓN HEPATO-PANCREATO-BILIAR DE GUATEMALA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "ASOCIACIÓN DE MEDICINA DE INTERNA DE GUATEMALA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/LVXsgIK2DXs?si=EDrjfvoQCqBP7alc",
          sala: 2,
        },
        {
          titulo: "ASOCIACIÓN DE GINECOLOGÍA Y OBSTETRICIA DE GUATEMALA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN DE CIRUJANOS DE GUATEMALA",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "12:30",
      horaFin: "13:29",
      datos: [
        {
          titulo: "ALMUERZO",
          salon: "Descanso",
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/LVXsgIK2DXs?si=EDrjfvoQCqBP7alc",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "CONFERENCIA SALÓN REYES",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "13:30",
      horaFin: "15:29",
      datos: [
        {
          titulo: "ASOCIACIÓN DE MEDICINA DEL DEPORTE DE GUATEMALA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "ASOCIACIÓN NACIONAL DE PATÓLOGOS DE GUATEMALA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/LVXsgIK2DXs?si=EDrjfvoQCqBP7alc",
          sala: 2,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE CARDIOLOGÍA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN PSIQUIÁTRICA DE GUATEMALA",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "15:30",
      horaFin: "15:59",
      datos: [
        {
          titulo: "CAFÉ",
          salon: "Descanso",
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/LVXsgIK2DXs?si=EDrjfvoQCqBP7alc",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "CONFERENCIA SALÓN REYES",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "16:00",
      horaFin: "17:59",
      datos: [
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE CIRUGÍA DE LA MANO",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/0nmSyRKSPhM?si=SFCMPbIePFpJm3nd",
          sala: 1,
        },
        {
          titulo: "ASOCIACIÓN DE BIOÉTICA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/yVEPx3JxR4Q?si=8277Ds8nJ1axAZ7c",
          sala: 2,
        },
        {
          titulo: "ASOCIACIÓN DE MEDICINA FÍSICA Y REHABILITACIÓN",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/CuRc-0ZP5BA?si=WzGmuDsDEjIFCZVW",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN DE ALERGIA ASMA E INMUNOLOGÍA DE GUATEMALA",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/pYWBfcVteEY?si=w8opUVIPnSAD_O2f",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "18:00",
      horaFin: "23:59",
      datos: [
        {
          titulo: "Gracias volveremos mañana",
          salon: "Congreso Médico",
        },
      ],
    },
  ],
  miercoles: [
    {
      horaInicio: "00:00",
      horaFin: "06:59",
      datos: [
        {
          titulo: "Gracias volveremos en la mañana",
          salon: "Congreso Médico",
        },
      ],
    },
    {
      horaInicio: "07:00",
      horaFin: "09:59",
      datos: [
        {
          titulo:
            "ASOCIACIÓN GUATEMALTECA DE CIENCIAS NEUROLÓGICAS O ASOCIACIÓN DE CIENCIAS NEUROLÓGICAS",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE OFTALMOLOGÍA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE ENFERMEDADES INFECCIOSAS",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
        {
          titulo:
            "ASOCIACIÓN GUATEMALTECA DE OTORRINOLARINGOLOGÍA, CIRUGÍA DE CABEZA Y CUELLO",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "10:00",
      horaFin: "10:29",
      datos: [
        {
          titulo: "CAFÉ",
          salon: "Descanso",
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
        {
          titulo: "CONFERENCIA SALÓN REYES",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "10:30",
      horaFin: "12:29",
      datos: [
        {
          titulo: "ASOCIACIÓN PEDIÁTRICA DE GUATEMALA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo: "ASOCIACIÓN MÉDICA GUATEMALTECA DE NEURAL TERAPIA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo:
            "ASOCIACIÓN CIVIL DE DIAGNÓSTICO POR IMÁGENES Y TERAPIA RADIANTE DE GUATEMALA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN DE NEUMOLOGÍA Y CIRUGÍA DE TÓRAX",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "12:30",
      horaFin: "13:29",
      datos: [
        {
          titulo:
            "CONFERENCIA ALMUERZO: NICOTINA Y REDUCCIÓN DE DAÑOS POR TABAQUISMO",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
      ],
    },
    {
      horaInicio: "13:30",
      horaFin: "15:29",
      datos: [
        {
          titulo: "INACIF",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo: "ENDOCROLOGÍA DE ADULTOS",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo: "MEDICINA DE EMERGENCIA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
        {
          titulo: "NEFROLOGÍA PEDIÁTRICA",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "15:30",
      horaFin: "15:59",
      datos: [
        {
          titulo: "CAFÉ",
          salon: "Descanso",
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
        {
          titulo: "CONFERENCIA SALÓN REYES",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "16:00",
      horaFin: "17:59",
      datos: [
        {
          titulo: "ASOCIACIÓN DE MEDICINA DEL TRABAJO DE GUATEMALA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/FiQ9SuRnzok?si=e9fI4lGj1IpUWWCw",
          sala: 1,
        },
        {
          titulo:
            "ASOCIACIÓN DE MEDICINA BIOLÓGICA, HOMOTOXICOLOGÍA Y HOMEOPATÍA DE GUATEMALA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/GUOFjreE5zQ?si=Hc29hr8e3NX6bqc0",
          sala: 2,
        },
        {
          titulo:
            "ASOCIACIÓN GUATEMALTECA DE ANESTESIOLOGÍA REANIMACIÓN Y TRATAMIENTO DEL DOLOR",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/Sk-W9eA_OHQ?si=6VA6g2rVtnNXSvxX",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE DERMATOLOGÍA",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/9jZ8P176u_k?si=HYv1Dj2mB_ArnAfn",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "18:00",
      horaFin: "23:59",
      datos: [
        {
          titulo: "Gracias volveremos mañana",
          salon: "Congreso Médico",
        },
      ],
    },
  ],
  jueves: [
    {
      horaInicio: "00:00",
      horaFin: "06:59",
      datos: [
        {
          titulo: "Gracias volveremos en la mañana",
          salon: "Congreso Médico",
        },
      ],
    },
    {
      horaInicio: "07:00",
      horaFin: "09:59",
      datos: [
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE FLEBOLOGÍA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/8EUncvKTD-I?si=sUf0NlBc8t8K-KoU",
          sala: 1,
        },
        {
          titulo: "EDUCACIÓN MÉDICA CONTINUA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/chPocLFAKJQ?si=qXxfK9yUzTTw4JhK",
          sala: 2,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE ORTOPEDIA Y TRAUMATOLOGÍA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/I2n9mjRfvtM?si=mWScCVI88fdRacPZ",
          sala: 3,
        },
        {
          titulo:
            "ASOCIACIÓN DE GASTROENTEROLOGÍA, HEPATOLOGÍA Y ENDOSCOPÍA GASTROINTESTINAL",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/H319pwPaliA?si=RnQ0Lee_HpcxtbZ0",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "10:00",
      horaFin: "10:29",
      datos: [
        {
          titulo: "CAFÉ",
          salon: "Descanso",
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/8EUncvKTD-I?si=sUf0NlBc8t8K-KoU",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/chPocLFAKJQ?si=qXxfK9yUzTTw4JhK",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/I2n9mjRfvtM?si=mWScCVI88fdRacPZ",
          sala: 3,
        },
        {
          titulo: "CONFERENCIA SALÓN REYES",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/H319pwPaliA?si=RnQ0Lee_HpcxtbZ0",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "10:30",
      horaFin: "12:29",
      datos: [
        {
          titulo: "PRESENTACIÓN DE TRABAJOS LIBRES - PREMIACIÓN",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/8EUncvKTD-I?si=sUf0NlBc8t8K-KoU",
          sala: 1,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE NEUROLOGÍA PEDIÁTRICA",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/chPocLFAKJQ?si=qXxfK9yUzTTw4JhK",
          sala: 2,
        },
        {
          titulo: "ASOCIACIÓN DE CIRUGÍA PEDIÁTRICA DE GUATEMALA",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/I2n9mjRfvtM?si=mWScCVI88fdRacPZ",
          sala: 3,
        },
        {
          titulo: "ASOCIACIÓN GUATEMALTECA DE MEDICINA DEL ADULTO",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/H319pwPaliA?si=RnQ0Lee_HpcxtbZ0",
          sala: 4,
        },
      ],
    },
    {
      horaInicio: "12:30",
      horaFin: "13:39",
      datos: [
        {
          titulo:
            "CONFERENCIA ALMUERZO: EVIDENCIAS Y TENDENCIAS DE LOS PROBIÓTICOS EN SII Y SIBO ",
          salon: "Salón Reyes",
          link: "https://www.youtube.com/embed/H319pwPaliA?si=RnQ0Lee_HpcxtbZ0",
          sala: 4,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 1",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/8EUncvKTD-I?si=sUf0NlBc8t8K-KoU",
          sala: 1,
        },
        {
          titulo: "CONFERENCIA SALÓN NACIONES 2",
          salon: "Salón Naciones 2",
          link: "https://www.youtube.com/embed/chPocLFAKJQ?si=qXxfK9yUzTTw4JhK",
          sala: 2,
        },
        {
          titulo: "CONFERENCIA SALÓN DORADO",
          salon: "Salón Dorado",
          link: "https://www.youtube.com/embed/I2n9mjRfvtM?si=mWScCVI88fdRacPZ",
          sala: 3,
        },
      ],
    },
    {
      horaInicio: "13:40",
      horaFin: "18:59",
      datos: [
        {
          titulo: "Gracias, la cena de gala comenzará a las 19:00",
          salon: "Congreso Médico",
        },
      ],
    },
    {
      horaInicio: "19:00",
      horaFin: "23:59",
      datos: [
        {
          titulo: "CENA DE GALA",
          salon: "Salón Naciones 1",
          link: "https://www.youtube.com/embed/8EUncvKTD-I?si=sUf0NlBc8t8K-KoU",
          sala: 1,
        },
      ],
    },
  ],
};

export default horario;
