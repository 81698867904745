import React, { useState, useEffect } from "react";
//Material ui
import {
  Grid,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
//Project imports
import { db, funciones_fb, analitics } from "../../services/firebase";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import config from "../../config";

//img
import logoComision from "../../images/cm/LogoComision.png";
import logoColegio from "../../images/cm/LogoColegio.png";

function Formulario(props) {
  const { cambiar, inicio, datos, setDatos } = props;

  var classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = React.useState(false);
  const [emainError, setEmainError] = React.useState("");
  const [profesionError, setProfesionError] = React.useState("");
  const [colegiadoError, setColegiadoError] = React.useState("");
  const [carnetError, setCarnetError] = React.useState("");
  const [universidadError, setUniversidadError] = React.useState("");
  const [cuiError, setCuiError] = React.useState("");
  const [token, setToken] = React.useState("");

  //modo
  const [medico, setMedico] = useState(false);
  const [estudiante, setEstudiante] = useState(false);
  const [publico, setPublico] = useState(false);
  const [organizacion, setOrganizacion] = useState(false);

  const [correoReg, setCorreoReg] = useState("");
  const [consultado, setConsultado] = useState(true);
  const [registrado, setRegistro] = useState(false);
  const [msgError, setMsgError] = useState("Por favor, intente de nuevo.");

  useEffect(() => {
    // loadReCaptcha("6Lc2hYonAAAAAMl2TtSPOatlmigxXbjrjKJ811vI");
  }, []);

  const handleChangeNombre = (event) => {
    setDatos({
      ...datos,
      nombre: event.target.value,
    });
  };

  const handleChangeCorreo = (event) => {
    setEmainError("");
    if (event.target.value.includes(" ")) {
      return;
    }
    setDatos({
      ...datos,
      correo: event.target.value,
    });
  };

  const handleChangeOrganizacion = (event) => {
    setDatos({
      ...datos,
      organizacion: event.target.value,
    });
  };

  const handleChangeTelefono = (event) => {
    const numTelefono = event.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(numTelefono) || numTelefono === "") {
      setDatos((prevDatos) => ({
        ...prevDatos,
        telefono: numTelefono,
      }));
    }
  };

  const handleChangeCarne = (event) => {
    setCarnetError("");
    const numCarne = event.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(numCarne) || numCarne === "") {
      setDatos((prevDatos) => ({
        ...prevDatos,
        carnet: numCarne,
      }));
    }
  };

  const handleChangeColegiado = (event) => {
    setColegiadoError("");
    const numColegiado = event.target.value;
    // console.log(event.target.value);
    setDatos((prevDatos) => ({
      ...prevDatos,
      colegiado: numColegiado,
    }));
  };

  const handleChangeCui = (event) => {
    setCuiError("");
    const numCui = event.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(numCui) || numCui === "") {
      setDatos((prevDatos) => ({
        ...prevDatos,
        cui: numCui,
      }));
    }
  };

  const handleChangeUniversidad = (event) => {
    setUniversidadError("");
    setDatos((prevDatos) => ({
      ...prevDatos,
      universidad: event.target.value,
    }));
  };

  const handleChangeProfesion = (event) => {
    setProfesionError("");
    setDatos((prevDatos) => ({
      ...prevDatos,
      profesion: event.target.value,
    }));
  };

  const fetchGuardar = (categoria, json, token) => {
    return fetch(`${config.server}/save_data/${categoria}`, {
      method: "POST",
      body: json,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((response) => response);
  };

  const ConsultarDatos = async () => {
    const regexe = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    try {
      setIsLoading(true);

      if (!regexe.test(datos.correo)) {
        setEmainError("Error ingreso un correo no valido");
        setIsLoading(false);
      } else {
        setEmainError("");
        await fetch(`${config.server}/getToken`, {
          method: "POST",
          body: JSON.stringify({
            username: "cmedicina",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (medico) {
              if (
                datos.profesion.length !== 0 &&
                datos.colegiado.length !== 0
              ) {
                let dataJson = JSON.stringify({
                  profesion: datos.profesion,
                  colegiado: datos.colegiado,
                  correo: datos.correo,
                  nombre: datos.nombre,
                  telefono: datos.telefono,
                  eliminado: false,
                });
                // console.log(dataJson);

                fetchGuardar(1, dataJson, data.token).then((datas) => {
                  if (datas.status === 200) {
                    setRegistro(true);
                    setConsultado(true);
                    setIsLoading(false);
                  } else if (datas.status === 400) {
                    setError(true);
                    setIsLoading(false);
                    setCorreoReg("warning");
                    setMsgError("El correo ingresado ya fue registrado");
                  } else {
                    setError(true);
                    setIsLoading(false);
                    setCorreoReg("error");
                    setMsgError(
                      "Por favor, verifique los datos ingresados e intente de nuevo."
                    );
                  }
                });
              } else {
                if (datos.profesion.length === 0) {
                  setProfesionError("Campo Obligatorio");
                }
                if (datos.colegiado.length === 0) {
                  setColegiadoError("Campo Obligatorio");
                }
                setError(true);
                setIsLoading(false);
                setCorreoReg("error");
                setMsgError(
                  "Es necesario completar todos los campos obligatorios."
                );
              }
            }
            if (estudiante) {
              if (datos.carnet.length !== 0 && datos.universidad.length !== 0) {
                let dataJson = JSON.stringify({
                  carnet: datos.carnet,
                  correo: datos.correo,
                  nombre: datos.nombre,
                  telefono: datos.telefono,
                  universidad: datos.universidad,
                  eliminado: false,
                });
                fetchGuardar(2, dataJson, data.token).then((datas) => {
                  if (datas.status === 200) {
                    setRegistro(true);
                    setConsultado(true);
                    setIsLoading(false);
                  } else if (datas.status === 400) {
                    setError(true);
                    setIsLoading(false);
                    setCorreoReg("warning");
                    setMsgError("El correo ingresado ya fue registrado");
                  } else {
                    setError(true);
                    setIsLoading(false);
                    setCorreoReg("error");
                    setMsgError(
                      "Por favor, verifique los datos ingresados e intente de nuevo."
                    );
                  }
                });
              } else {
                if (datos.carnet.length === 0) {
                  setCarnetError("Campo Obligatorio");
                }
                if (datos.universidad.length === 0) {
                  setUniversidadError("Campo Obligatorio");
                }
                setError(true);
                setIsLoading(false);
                setCorreoReg("error");
                setMsgError(
                  "Es necesario completar todos los campos obligatorios."
                );
              }
            }

            if (publico) {
              if (datos.cui.length !== 0) {
                let dataJson = JSON.stringify({
                  cui: datos.cui,
                  organizacion: datos.organizacion,
                  correo: datos.correo,
                  nombre: datos.nombre,
                  telefono: datos.telefono,
                  eliminado: false,
                });
                fetchGuardar(3, dataJson, data.token).then((datas) => {
                  if (datas.status === 200) {
                    setRegistro(true);
                    setConsultado(true);
                    setIsLoading(false);
                  } else if (datas.status === 400) {
                    setError(true);
                    setIsLoading(false);
                    setCorreoReg("warning");
                    setMsgError("El correo ingresado ya fue registrado");
                  } else {
                    setError(true);
                    setIsLoading(false);
                    setCorreoReg("error");
                    setMsgError(
                      "Por favor, verifique los datos ingresados e intente de nuevo."
                    );
                  }
                });
              } else {
                if (datos.cui.length === 0) {
                  setCuiError("Campo Obligatorio");
                }
                setError(true);
                setIsLoading(false);
                setCorreoReg("error");
                setMsgError(
                  "Es necesario completar todos los campos obligatorios."
                );
              }
            }
          });
      }
    } catch (error) {
      setIsLoading(false);
      setMsgError("Por favor, recargue la página e intente de nuevo.");
    }
  };

  const handleChangeSwitch = (event) => {
    setOrganizacion(event.target.checked);
  };

  const escogerModo = () => {
    setUniversidadError("");
    setCuiError("");
    setCarnetError("");
    setColegiadoError("");
    setProfesionError("");
    setEmainError("");
    setConsultado(true);
    setMedico(false);
    setEstudiante(false);
    setPublico(false);
    setRegistro(false);
    setError(false);
    setDatos({
      carnet: "",
      nombre: "",
      correo: "",
      telefono: "",
      universidad: "",
      organizacion: "",
      cui: "",
      colegiado: "",
      profesion: "",
    });
  };

  const modoMedico = () => {
    setUniversidadError("");
    setCuiError("");
    setCarnetError("");
    setColegiadoError("");
    setProfesionError("");
    setEmainError("");
    setConsultado(false);
    setMedico(true);
    setEstudiante(false);
    setPublico(false);
    setRegistro(false);
    setError(false);
    setDatos({
      carnet: "",
      nombre: "",
      correo: "",
      telefono: "",
      universidad: "",
      organizacion: "",
      cui: "",
      colegiado: "",
      profesion: "",
    });
  };

  const modoEstudiante = () => {
    setUniversidadError("");
    setCuiError("");
    setCarnetError("");
    setColegiadoError("");
    setProfesionError("");
    setEmainError("");
    setConsultado(false);
    setMedico(false);
    setEstudiante(true);
    setPublico(false);
    setRegistro(false);
    setError(false);
    setDatos({
      carnet: "",
      nombre: "",
      correo: "",
      telefono: "",
      universidad: "",
      organizacion: "",
      cui: "",
      colegiado: "",
      profesion: "",
    });
  };

  const modoPublico = () => {
    setUniversidadError("");
    setCuiError("");
    setCarnetError("");
    setColegiadoError("");
    setProfesionError("");
    setEmainError("");
    setConsultado(false);
    setMedico(false);
    setEstudiante(false);
    setPublico(true);
    setError(false);
    setDatos({
      carnet: "",
      nombre: "",
      correo: "",
      telefono: "",
      universidad: "",
      organizacion: "",
      cui: "",
      colegiado: "",
      profesion: "",
    });
  };

  const irInicio = () => {
    setUniversidadError("");
    setCuiError("");
    setCarnetError("");
    setColegiadoError("");
    setProfesionError("");
    setEmainError("");
    setConsultado(true);
    setMedico(false);
    setEstudiante(false);
    setPublico(false);
    setError(false);
    setDatos({
      carnet: "",
      nombre: "",
      correo: "",
      telefono: "",
      universidad: "",
      organizacion: "",
      cui: "",
      colegiado: "",
      profesion: "",
    });
    inicio();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />

      <Grid
        item
        xs={12}
        style={{
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255)",
        }}
      >
        {
          !consultado ? (
            <Grid
              container
              spacing={4}
              alignItems={"center"}
              justify={"space-between"}
              direction={"row"}
            >
              <Grid item xs={12}>
                <Typography
                  align={"center"}
                  variant={"h2"}
                  className={classes.textotitulo}
                  color={"primary"}
                >
                  Registro
                </Typography>
              </Grid>
              {estudiante ? (
                <>
                  <Grid item xs={12}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={universidadError ? true : false}
                      helperText={universidadError ? universidadError : ""}
                    >
                      <InputLabel>Selecciona tu universidad</InputLabel>
                      <Select
                        value={datos.universidad}
                        label="universidad"
                        onChange={handleChangeUniversidad}
                      >
                        <MenuItem
                          value={"Universidad de San Carlos de Guatemala"}
                        >
                          Universidad de San Carlos de Guatemala
                        </MenuItem>
                        <MenuItem value={"Universidad Rafael Landívar"}>
                          Universidad Rafael Landívar
                        </MenuItem>
                        <MenuItem value={"Universidad del Valle de Guatemala"}>
                          Universidad del Valle de Guatemala
                        </MenuItem>
                        <MenuItem value={"Universidad Francisco Marroquín"}>
                          Universidad Francisco Marroquín
                        </MenuItem>
                        <MenuItem value={"Universidad del Istmo"}>
                          Universidad del Istmo
                        </MenuItem>
                        <MenuItem
                          value={"Universidad Panamericana de Guatemala"}
                        >
                          Universidad Panamericana de Guatemala
                        </MenuItem>
                        <MenuItem value={"Universidad Mesoamericana"}>
                          Universidad Mesoamericana
                        </MenuItem>
                        <MenuItem value={"Universidad Galileo"}>
                          Universidad Galileo
                        </MenuItem>
                        <MenuItem value={"Universidad San Pablo de Guatemala"}>
                          Universidad San Pablo de Guatemala
                        </MenuItem>
                        <MenuItem
                          value={"Universidad Mariano Gálvez de Guatemala"}
                        >
                          Universidad Mariano Gálvez de Guatemala
                        </MenuItem>
                        <MenuItem value={"Universidad InterNaciones"}>
                          Universidad InterNaciones
                        </MenuItem>
                        <MenuItem value={"Universidad Rural de Guatemala	"}>
                          Universidad Rural de Guatemala
                        </MenuItem>
                        <MenuItem
                          value={"Escuela Nacional Central de Agricultura"}
                        >
                          Escuela Nacional Central de Agricultura
                        </MenuItem>
                        <MenuItem value={"Universidad Regional de Guatemala"}>
                          Universidad Regional de Guatemala
                        </MenuItem>
                        <MenuItem value={"Universidad Da Vinci de Guatemala"}>
                          Universidad Da Vinci de Guatemala
                        </MenuItem>
                        <MenuItem value={"Otro"}>Otro</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={datos.carnet}
                      onChange={handleChangeCarne}
                      id="carne"
                      label="Número de carné*"
                      variant="outlined"
                      error={carnetError ? true : false}
                      helperText={carnetError ? carnetError : ""}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {medico ? (
                <>
                  <Grid item xs={12}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={profesionError ? true : false}
                      helperText={profesionError ? profesionError : ""}
                    >
                      <InputLabel>Selecciona tu profesión</InputLabel>
                      <Select
                        value={datos.profesion}
                        label="profesión"
                        onChange={handleChangeProfesion}
                      >
                        <MenuItem value={"Médico"}>Médico (a)</MenuItem>
                        <MenuItem value={"Licenciado en enfermería"}>
                          Licenciado (a) en enfermería
                        </MenuItem>
                        <MenuItem value={"Licenciado en fisioterapia"}>
                          Licenciado (a) en fisioterapia
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={datos.colegiado}
                      onChange={handleChangeColegiado}
                      id="colegiado"
                      label="Colegiado*"
                      variant="outlined"
                      error={colegiadoError ? true : false}
                      helperText={colegiadoError ? colegiadoError : ""}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}

              {publico ? (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={datos.cui}
                    onChange={handleChangeCui}
                    id="cui"
                    label="CUI*"
                    variant="outlined"
                    error={cuiError ? true : false}
                    helperText={cuiError ? cuiError : ""}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={datos.nombre}
                  onChange={handleChangeNombre}
                  id="nombreCompleto"
                  label="Nombre completo"
                  variant="outlined"
                  helperText={
                    medico
                      ? "Este mombre será utilizado para tu diploma de acreditación"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={datos.correo}
                  type="text"
                  onChange={handleChangeCorreo}
                  id="correoElectronico"
                  label="Correo electrónico"
                  variant="outlined"
                  error={emainError ? true : false}
                  helperText={emainError ? emainError : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={datos.telefono}
                  onChange={handleChangeTelefono}
                  id="telefono"
                  label="Número de teléfono"
                  variant="outlined"
                />
              </Grid>

              {publico ? (
                <>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={organizacion}
                            onChange={handleChangeSwitch}
                          />
                        }
                        label="Nos visita de una organización"
                      />
                    </FormGroup>
                  </Grid>
                  {organizacion ? (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={datos.Organizacion}
                        onChange={handleChangeOrganizacion}
                        id="organizacion"
                        label="Nombre de la organización*"
                        variant="outlined"
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {error && (
                <Grid item xs={12}>
                  <Alert severity={correoReg} onClose={() => setError(false)}>
                    {msgError}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                {isLoading ? (
                  <div className={classes.textotitulo}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    disabled={
                      datos.nombre.length === 0 ||
                      datos.telefono.length === 0 ||
                      datos.correo.length === 0 ||
                      emainError
                    }
                    variant="contained"
                    color="secondary"
                    onClick={ConsultarDatos}
                    className={classes.estiloboton}
                  >
                    REGISTRARME
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="center"
                  onClick={escogerModo} // Actualiza el estado de registrarse a true al hacer clic
                  style={{ cursor: "pointer" }}
                >
                  <strong>ATRÁS</strong>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                spacing={6}
                alignItems={"center"}
                justify={"space-between"}
                direction={"row"}
                className={classes.centered}
              >
                {registrado ? (
                  <>
                    <Grid
                      container
                      spacing={6}
                      alignItems={"center"}
                      justify={"space-between"}
                      direction={"row"}
                    >
                      <Grid item xs={12} alignItems="center" direction="row">
                        <Typography
                          align={"center"}
                          variant={"h3"}
                          className={classes.textotitulo}
                          color={"primary"}
                          style={{ margin: "2vh" }}
                        >
                          Recibirás un correo electrónico de confirmación de
                          inscripción que incluirá tu código QR y las
                          credenciales necesarias para futuros ingresos a este
                          portal. Descarga tu código QR, el cual funcionará como
                          tu gafete electrónico para el control de asistencia en
                          el Hotel Barceló.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} align={"center"}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={escogerModo}
                          className={classes.estiloboton2}
                        >
                          REGRESAR AL INICIO
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    container
                    spacing={4}
                    alignItems={"center"}
                    justify={"space-between"}
                    direction={"row"}
                    className={classes.centered}
                  >
                    <Grid container direction={"row"}>
                      <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                      <Grid item xs={3} sx={{ pb: 10 }}>
                        <img
                          className={classes.img}
                          alt="complex"
                          src={logoComision}
                          style={{
                            marginTop: "5vh",
                            maxWidth: "90%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} sx={{ pb: 10 }}>
                        <img
                          className={classes.img}
                          alt="complex"
                          src={logoColegio}
                          style={{ marginTop: "5vh", maxWidth: "90%" }}
                        />
                      </Grid>
                      <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                    </Grid>
                    <Grid container direction={"row"}>
                      <Grid item xs={12} style={{ margin: "2vh" }}>
                        <Typography
                          align={"center"}
                          variant={"h5"}
                          className={classes.textotitulo}
                          color={"primary"}
                          style={{ color: "#585756", marginTop: "1vh" }}
                        >
                          LXV CONGRESO NACIONAL DE MEDICINA
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align={"center"}>
                          IX CONGRESO DE MÉDICOS ESCRITORES
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} align={"center"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={modoMedico}
                        className={classes.estiloboton2}
                      >
                        AGREMIADO
                      </Button>
                    </Grid>

                    <Grid item xs={12} align={"center"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={modoEstudiante}
                        className={classes.estiloboton2}
                      >
                        ESTUDIANTE
                      </Button>
                    </Grid>
                    <Grid item xs={12} align={"center"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={modoPublico}
                        className={classes.estiloboton2}
                      >
                        PÚBLICO AFIN
                      </Button>
                    </Grid>
                    <Grid item xs={12} align={"center"}>
                      <Button
                        onClick={irInicio}
                        className={classes.estiloboton2}
                      >
                        Volver al inicio
                      </Button>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} />
                  </Grid>
                )}
              </Grid>
            </>
          )
          // )
        }
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />
    </Grid>
  );
}

export default Formulario;
