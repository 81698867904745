import React, { useState, useEffect } from "react";

var UserContext = React.createContext();

const UserProvider2 = ({ children }) => {
  const [userState, setUserState] = useState({
    isAuthenticated: false,
    user: null,
    tipo: 0,
  });

  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(
      "useUserState debe ser utilizado dentro de un UserStateProvider"
    );
  }
  return context;
};

export { UserProvider2, useUser };
